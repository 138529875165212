interface SearchIconProps {
    width: string;
    height: string;
}

const searchIconProps = {
    width: '20',
    height: '20',
};

export const SearchIcon = (props: SearchIconProps = searchIconProps) => {
    const { width, height } = props;

    return (
        <>
            <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2972_16781)">
                    <path
                        d="M8.80758 0C3.95121 0 0 3.95121 0 8.80758C0 13.6642 3.95121 17.6152 8.80758 17.6152C13.6642 17.6152 17.6152 13.6642 17.6152 8.80758C17.6152 3.95121 13.6642 0 8.80758 0ZM8.80758 15.9892C4.84769 15.9892 1.62602 12.7675 1.62602 8.80762C1.62602 4.84773 4.84769 1.62602 8.80758 1.62602C12.7675 1.62602 15.9891 4.84769 15.9891 8.80758C15.9891 12.7675 12.7675 15.9892 8.80758 15.9892Z"
                        fill="#1B8E95"
                    />
                    <path
                        d="M19.762 18.6104L15.1007 13.9492C14.7831 13.6315 14.2687 13.6315 13.9511 13.9492C13.6335 14.2665 13.6335 14.7814 13.9511 15.0988L18.6124 19.76C18.7711 19.9188 18.979 19.9982 19.1872 19.9982C19.395 19.9982 19.6031 19.9188 19.762 19.76C20.0796 19.4427 20.0796 18.9277 19.762 18.6104Z"
                        fill="#1B8E95"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_2972_16781">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};
