import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ErrorText } from 'src/components/Error/ErrorText';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { START_EVALUATION_MUTATION } from 'src/graphql/mutations/mutations';
import { GET_USER_MODULE_INFO_BY_MODULE_ID } from 'src/graphql/queries/queries';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import { selectCurrentModule } from 'src/redux/features/listModules/listModulesSlice';
import { ModuleType } from 'src/utils/constants/constants';
import EvaluationStudentEducativeBilan from '../EvaluationStudent/EvaluationStudentEducativeBilan';
import AnnotedVideoPanel from './Video/AnnotedVideoPanel';
import FirstVideoPanel from './Video/FirstVideoPanel';
import { Box } from '@mui/material';
import { Spacer } from 'src/components/Spacer/Spacer';
import { AvancementContext } from 'src/providers/AvancementProvider';

const ModuleDetailStudentPanel = (props: any) => {
    const currentModule = useSelector(selectCurrentModule);
    const moduleId = currentModule?.id;

    const moduleType = props.moduleType || 'EDUCATIVE BILAN';
    let firstVideoType = ModuleType.VIDEO;
    let videoAnnotedType = ModuleType.VIDEO_ANNOTE;
    const {
        loading: loadingUserModuleInfo,
        data: userModuleInfoData,
        error: errorGetUserModuleInfo,
        refetch,
    } = useQuery(GET_USER_MODULE_INFO_BY_MODULE_ID, {
        variables: { moduleId, moduleType },
        skip: !moduleId || !currentModule,
    });

    const [videoFile, setVideoFile] = useState<any>();
    const [annotedVideoFile, setAnnotedVideoFile] = useState<any>();
    const [loadingVideo, setLoadingVideo] = useState<boolean>(false);
    const [videoUrl, setVideoUrl] = useState();
    const [annotedVideoUrl, setAnnotedVideoUrl] = useState();
    const [evaluationStatus, setEvaluationStatus] = useState<boolean>();
    const [userModuleInfo, setUserModuleInfo] = useState<any>();
    const [moduleTitle, setModuleTitle] = useState<any>();
    const [loadingSetUserModuleInfo, setLoadingSetUserModuleInfo] = useState<boolean>(false);
    const [hasPdfResponse, setHasPdfResponse] = useState<boolean>(false);
    const [isFinalizedStatus, setIsFinalizedStatus] = useState<boolean>(false);
    const [hasSeenVideo, setHasSeenVideo] = useState<boolean>(false);
    const [hasSeenVideoAnnote, setHasSeenVideoAnnote] = useState<boolean>(false);
    const [progressionVideo, setProgressionVideo] = useState<number>(0);
    const [progressionVideoAnnote, setProgressionVideoAnnote] = useState<number>(0);
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);
    const {setReload} = useContext(AvancementContext);

    useEffect(() => {
        if (userModuleInfoData?.getUserModuleInfoByModule) {
            let data = userModuleInfoData.getUserModuleInfoByModule;
            setUserModuleInfo(userModuleInfoData);

            setLoadingVideo(true);

            setVideoFile(data.module.video);
            setAnnotedVideoFile(data.module.videoAnnote);
            setModuleTitle(data.module.title);

            if (data.module.video) {
                setVideoUrl(data.module.video.fileUrl);
            }
            if (data.module.videoAnnote) {
                setAnnotedVideoUrl(data.module.videoAnnote.fileUrl);
            }
            setLoadingVideo(false);

            if (data.evaluationIsStarted) {
                setEvaluationStatus(data.evaluationIsStarted);
            }
            if (data.isFinalized) {
                setIsFinalizedStatus(data.isFinalized);
            }
            setHasSeenVideo(data.hasSeenVideo);
            setHasSeenVideoAnnote(data.hasSeenVideoAnnote);
            setProgressionVideo(data.progressionVideo);
            setProgressionVideoAnnote(data.progressionVideoAnnote);
            data.pdfResponse ? setHasPdfResponse(true) : setHasPdfResponse(false);
            setReload(true);
        }
    }, [userModuleInfoData]);

    useEffect(() => {
        setLoadingSetUserModuleInfo(true);
        refetch().then(({ data: userModuleInfoData }) => {
            setUserModuleInfo(userModuleInfoData);
            setEvaluationStatus(userModuleInfoData?.getUserModuleInfoByModule?.evaluationIsStarted);
            setIsFinalizedStatus(userModuleInfoData?.getUserModuleInfoByModule?.isFinalized);
            userModuleInfoData?.getUserModuleInfoByModule?.pdfResponse
                ? setHasPdfResponse(true)
                : setHasPdfResponse(false);
        });
        setLoadingSetUserModuleInfo(false);
    }, [moduleId]);

    const onCompletedFinalizeModule = (data: any) => {
        setIsFinalizedStatus(true);
        showSuccessSnackBar('Module finalisé avec succès');
        console.log('call de reload');
        setTimeout( () => { window.location.reload() }, 1500 );
    };
    const onCompletedStartEvaluation = (data: any) => {
        setEvaluationStatus(true);
    };
    const onErrorMutation = (error: any) => {
        showErrorSnackBar("Une erreur s'est produit, veuillez reessayer ulterieurement.");
        console.log(error);
    };
    const [startEvaluationOnBackend, { loading, data, error }] = useMutation(START_EVALUATION_MUTATION, {
        variables: { moduleId, moduleType },
        onCompleted: onCompletedStartEvaluation,
        onError: onErrorMutation,
    });
    const triggerShowAnnotedVideo = (hasPdfResponse: boolean) => {
        setHasPdfResponse(hasPdfResponse);
    };

    if (errorGetUserModuleInfo) {
        console.log(errorGetUserModuleInfo);
    }
    return (
        <Box
            sx={{
                backgroundColor: 'white',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'white',
                    height: '80vh',
                    p: '25px',
                    boxSizing: 'border-box',
                    overflowY: 'auto',
                }}
            >
                {loadingUserModuleInfo ? (
                    <Box
                        sx={{
                            marginTop: '35%',
                        }}
                    >
                        <CustomCircleLoader></CustomCircleLoader>
                    </Box>
                ) : errorGetUserModuleInfo ? (
                    <ErrorText>Une erreur est survenue lors du chargement des données</ErrorText>
                ) : !hasPdfResponse && !isFinalizedStatus ? (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <FirstVideoPanel
                                videoUrl={videoUrl}
                                videoFile={videoFile}
                                moduleTitle={moduleTitle}
                                moduleId={currentModule?.id}
                                moduleType={moduleType}
                                videoType={firstVideoType}
                                loadingVideo={loadingVideo}
                                startEvaluationOnBackend={startEvaluationOnBackend}
                                evaluationStatus={evaluationStatus}
                                hasSeenVideo={hasSeenVideo}
                                setHasSeenVideo={setHasSeenVideo}
                                progressionVideo={progressionVideo}
                            ></FirstVideoPanel>
                            {evaluationStatus && userModuleInfo && !loadingSetUserModuleInfo && (
                                <EvaluationStudentEducativeBilan
                                    moduleId={currentModule?.id}
                                    moduleType={moduleType}
                                    userModuleInfoData={userModuleInfo}
                                    triggerOk={triggerShowAnnotedVideo}
                                ></EvaluationStudentEducativeBilan>
                            )}
                        </div>
                    </div>
                ) : (
                    hasPdfResponse && (
                        <AnnotedVideoPanel
                            annotedVideoFile={annotedVideoFile}
                            annotedVideoUrl={annotedVideoUrl}
                            moduleTitle={moduleTitle}
                            moduleType={moduleType}
                            videoType={videoAnnotedType}
                            moduleId={currentModule?.id}
                            onCompletedFinalizeModule={onCompletedFinalizeModule}
                            onErrorMutation={onErrorMutation}
                            loadingVideo={loadingVideo}
                            isFinalizedStatus={isFinalizedStatus}
                            hasSeenVideoAnnote={hasSeenVideoAnnote}
                            setHasSeenVideoAnnote={setHasSeenVideoAnnote}
                            progressionVideo={progressionVideoAnnote}
                        ></AnnotedVideoPanel>
                    )
                )}
            </Box>
        </Box>
    );
};
export default ModuleDetailStudentPanel;
