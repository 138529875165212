import { Box } from '@mui/material';
import { baseScrollbar } from 'src/utils/constants/baseStyles';
import SettingPanelViewModel from '../SettingViewModel';
import { ReactNode, useContext } from 'react';
import { SettingMenuContext, SettingMenuItem } from 'src/providers/SettingMenuProvider';

const SubmenuSettingPanel = () => {
    const vm = new SettingPanelViewModel();
    const styles = vm.getStyles();
    const { settingMenuItems } = useContext(SettingMenuContext);


    const handleSelectedPanel = () => {
        let myNode: React.ReactNode = null; // Initialize myNode with null
        settingMenuItems.forEach((menuItem: SettingMenuItem) => { // Use forEach instead of map if not building a new array
            if (menuItem.selected) {
                myNode = menuItem.panel;
            }
        });
        console.log(myNode);
        return myNode;
    };
    return (
        <Box
            sx={{
                ...styles.box,
                marginLeft: '10px',
                flexBasis: 'auto',
                flexGrow: 1,
                padding: 4,
                overflowY: 'auto',
                ...baseScrollbar,
            }}
        >
            {handleSelectedPanel()}
        </Box>
    );
};

export { SubmenuSettingPanel };
