import { Box, Paper } from '@mui/material';
import { ExplicativeVideo } from 'src/pages/ExplicativeVideo/ExplicativeVideo';
import { useSidebarContext } from 'src/providers/SidebarProvider';
import ContentPanelHelper from './ContentPanelHelper';
import ContentPanelViewModel from './ContentPanelViewModel';

export const Content = (props: any) => {
    const viewModel = new ContentPanelViewModel();
    const helper = new ContentPanelHelper();

    const { selectedPanel } = useSidebarContext();

    const styles = viewModel.getStyles();
    return (
        <Box sx={{
            ...styles.content,
            '@media screen and (max-width: 750px)': {
                height: '100vh',
            },
        }}>
            <Paper sx={styles.paperContent}>{helper.displayPanel(selectedPanel)}</Paper>
            <ExplicativeVideo />
        </Box>
    );
};
