import { ReactNode, useState } from 'react';
import { createContext } from 'react';

type AvancementType = {
    reload: boolean;
    setReload: (val: boolean) => void;
    selectedUserId: string | null;
    selectedUserName: string | null;
    setSelectedUserId: (val: string | null) => void;
    setSelectedUserName: (val: string | null) => void;
    isUserSelected: boolean;
    setIsUserSelected: (val: boolean) => void;
};

export const AvancementContext = createContext<AvancementType>({
    selectedUserId: null,
    selectedUserName: null,
    setSelectedUserId: () => {},
    setSelectedUserName: (val: string | null) => {},
    isUserSelected: false,
    setIsUserSelected: () => {},
    reload: false,
    setReload: () => {},
});

type AvancementTypeProps = {
    children: ReactNode;
};

const AvancementProvider = (props: AvancementTypeProps) => {
    const { children } = props;
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [selectedUserName, setSelectedUserName] = useState<string | null>(null);
    const [isUserSelected, setIsUserSelected] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);

    return (
        <AvancementContext.Provider
            value={{
                selectedUserId,
                selectedUserName,
                setSelectedUserId,
                setSelectedUserName,
                isUserSelected,
                setIsUserSelected,
                reload,
                setReload
            }}
        >
            {children}
        </AvancementContext.Provider>
    );
};

export default AvancementProvider;
