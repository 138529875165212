import { MenuIconName, SIDEBAR_MENU_KEY } from 'src/utils/constants/constants';

class SidebarWrapperViewModel {
    private style = {
        wrapper: {
            display: 'flex',
            backgroundColor: 'white',
            flexDirection: 'column',
            marginBottom: '2px',
            borderRadius: '8px',
            width: '205px',
            minWidth: '205px',
            height: '100vh'
        },
    };

    public getStyles() {
        return this.style;
    }

    public getSelectedIndicator(menuItems: any, codes?: string[]): any {
        const sidebarMenuItems = menuItems.filter((sidebarMenu: any) => {
            if (!codes) {
                return false;
            }
            return codes.indexOf(sidebarMenu.code) !== -1;
        });
        const menus = sidebarMenuItems.filter((menu: any, index: number) => {
            const localMenu = localStorage.getItem(SIDEBAR_MENU_KEY);
            if (localMenu) {
                return menu.icon === localMenu;
            }
            return index === 0;
        });

        if (menus.length === 0) {
            return menuItems[0];
        }

        return menus[0];
    }
}

export default SidebarWrapperViewModel;
