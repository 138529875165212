import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { baseColors } from 'src/utils/constants/baseStyles';

interface CustomAnomalyProps {
    code: number;
    email: string;
    delay?: number;
}

const CustomAnomaly: React.FC<CustomAnomalyProps> = ({ code, email, delay = 60000 }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, delay);

        // Cleanup function to clear timeout when component unmounts or when delay changes
        return () => clearTimeout(timer);
    }, [delay]);

    let anomalyMessage;
    if (code === 0) {
        anomalyMessage = `L'email ${email} existe déjà`;
    } else if (code === 1) {
        anomalyMessage = `La promotion que vous avez associée à ${email} n'existe pas`;
    }

    if (!visible) {
        return null; // Don't render anything if not visible
      }

    return (
        <Box
            p={2}
            my={2}
            width="100%"
            bgcolor={baseColors.orange.light}
            color={baseColors.orange.dark}
            sx={{
                fontSize: '14px',
                boxSizing: 'border-box',
                borderRadius: '10px',
                fontFamily: 'Open Sans, sans-serif',
                display: 'flex',
            }}
            justifyContent='space-between'
            alignItems='center'
        >
            <span>{anomalyMessage}</span>
            <IconButton onClick={()=>{setVisible(false)}} sx={{ fontSize: 12 }}>
                <CloseIcon></CloseIcon>
            </IconButton>
        </Box>
    );
};

export default CustomAnomaly;
