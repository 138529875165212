import { ReactNode, useState } from 'react';
import { createContext } from 'react';
import UserSettingPanel from 'src/pages/Setting/SubmenuSettingPanel/UserSettingPanel/UserSettingPanel';
import PromotionSettingPanel from 'src/pages/Setting/SubmenuSettingPanel/PromotionSettingPanel/PromotionSettingPanel';
import { SettingMenuIcon } from 'src/utils/constants/constants';


// Define the type for a single setting menu item
export type SettingMenuItem = {
    id: number;
    icon: string; // You may replace `string` with the appropriate type for your icons
    title: string;
    selected: boolean;
    panel : ReactNode;
};

const initialSettingMenuItems: SettingMenuItem[] = [
    {
        id: 1,
        icon: SettingMenuIcon.USER,
        title: 'Utilisateurs',
        selected: true,
        panel: <UserSettingPanel />
    },
    {
        id: 2,
        icon: 'user', // Replace 'user' with your actual icon
        title: 'Promotions',
        selected: false,
        panel: <PromotionSettingPanel />
    },
];

// Define the type for the setting menu context
type SettingMenuType = {
    settingMenuItems: SettingMenuItem[];
    setSettingMenuItems: (val: SettingMenuItem[]) => void;
};

// Create the context for setting menu
export const SettingMenuContext = createContext<SettingMenuType>({
    settingMenuItems: [],
    setSettingMenuItems: () => {},
});

// Define props for SettingMenuProvider
type SettingMenuProviderProps = {
    children: ReactNode;
};

// Create the SettingMenuProvider component
const SettingMenuProvider = (props: SettingMenuProviderProps) => {
    const { children } = props;

    // Define initial settingMenuItems

    // State to manage settingMenuItems
    const [settingMenuItems, setSettingMenuItems] = useState<SettingMenuItem[]>(initialSettingMenuItems);

    // Return SettingMenuProvider with the context value
    return (
        <SettingMenuContext.Provider
            value={{
                settingMenuItems,
                setSettingMenuItems,
            }}
        >
            {children}
        </SettingMenuContext.Provider>
    );
};

export default SettingMenuProvider;
