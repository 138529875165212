import React, { createContext, ReactNode, useState } from 'react';

// Define the type for the reloading context
type ReloadingContextType = {
    isReload: boolean;
    setIsReload: (val: boolean) => void;
};

// Create the context for reloading
export const ReloadingContext = createContext<ReloadingContextType>({
    isReload: false,
    setIsReload: () => {},
});

// Define props for ReloadingProvider
type ReloadingProviderProps = {
    children: ReactNode;
};

// Create the ReloadingProvider component
const ReloadingProvider = (props: ReloadingProviderProps) => {
    const { children } = props;

    // State to manage reloading
    const [isReload, setIsReload] = useState<boolean>(false);

    // Return ReloadingProvider with the context value
    return (
        <ReloadingContext.Provider
            value={{
                isReload,
                setIsReload,
            }}
        >
            {children}
        </ReloadingContext.Provider>
    );
};

export default ReloadingProvider;
