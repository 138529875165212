import { USER_FRAGMENT } from '../fragments/fragments';
import { gql } from '@apollo/client';

const GET_URERS = gql`
    ${USER_FRAGMENT}
    query GetUsers {
        User {
            ...UserFields
        }
    }
`;

const GET_ALL_USERS = gql`
    ${USER_FRAGMENT}
    query GetAllUsers($keywords: String = "") {
        listUser(keywords: $keywords) {
            ...UserFields
        }
    }
`;

const GET_CURRENT_USER = gql`
    query GetCurrentUser {
        currentUser {
            id
            firstName
            lastName
            isFirstSession
            profilePicture {
                fileUrl
            }
        }
    }
`;

const GET_CURRENT_USER_DETAILS = gql`
    query GetCurrentUser {
        currentUser {
            id
            firstName
            lastName
            birthDate
            age
            birthPlace
            profession
            adress
            phoneNumber
            sex
            email
            profilePicture {
                fileUrl
            }
        }
    }
`;

const GET_CURRENT_EDUCATIVE_BILAN = gql`
    query currentEducativeBilan {
        currentEducativeBilan {
            student {
                id
                roles
            }
            moduleInfo {
                isFinalized
            }
            isValidated
        }
    }
`;

const LIST_STUDENTS = gql`
    query listStudent {
        listStudent(limit: 15) {
            student {
                id
                firstName
                lastName
                promotion {
                    id
                    name
                }
            }
            moduleInfo {
                progressionVideo
            }
        }
    }
`;

const LIST_STUDENTS_WITH_PROGRESSION = gql`
    query listStudentWithProgression($promotionId: String!) {
        listStudentWithProgression(promotionId: $promotionId) {
            student {
                id
                firstName
                lastName
                promotion {
                    id
                    name
                }
            }
            educativeBilan {
                seenVideos
                filledForm
            }
            educativeWorkshop {
                seenVideos
                filledForm
            }
        }
    }
`;

const GET_MODULE_BY_ID = gql`
    query getModuleById($moduleId: String!) {
        getModuleById(id: $moduleId) {
            type
            quizz {
                id
                type
                text
                choices {
                    text
                }
            }
        }
    }
`;

const GET_USER_MODULE_INFO_BY_MODULE_ID = gql`
    query getUserModuleInfoByModule($moduleId: String!, $moduleType: String!) {
        getUserModuleInfoByModule(moduleId: $moduleId, moduleType: $moduleType) {
            trame {
                questionId
                questionText
                answer
            }
            module {
                id
                title
                quizz {
                    id
                    type
                    text
                    choices {
                        text
                    }
                }
                video {
                    filePath
                    fileUrl
                    fileName
                    originalName
                    mimeType
                    category
                    title
                    description
                }
                videoAnnote {
                    filePath
                    fileUrl
                    fileName
                    originalName
                    mimeType
                    category
                    title
                    description
                }
            }
            evaluationIsStarted
            isFinalized
            pdfResponse {
                fileName
                filePath
            }
            hasSeenVideo
            hasSeenVideoAnnote
            progressionVideo
            progressionVideoAnnote
        }
    }
`;

const LIST_ALL_PROMOTIONS = gql`
    query GetAllPromotion {
        getAllPromotion {
            id
            name
            isDeletable
        }
    }
`;

const LIST_VIDEO_BY_MODULE = gql`
    query listVideoByModule($moduleId: String!) {
        listVideoByModule(moduleId: $moduleId) {
            id
            filePath
            originalName
            fileName
            title
            description
            fileUrl
            category
        }
    }
`;

const GET_USER_CONSENT = gql`
    query getUserConsentCurrentUser {
        getUserConsentCurrentUser {
            cgu
            major
            services
            statistics
            communication
        }
    }
`;

const GET_TRAME_STUDENT = gql`
    query getUserTrameByModuleId($moduleType: String!) {
        getUserTrameByModuleId(moduleType: $moduleType) {
            educativeBilan {
                module {
                    id
                }
                pdfResponse {
                    id
                    fileName
                    filePath
                    fileUrl
                    originalName
                    mimeType
                    category
                    title
                    description
                    createdAt
                }
            }
            educativeWorkshop {
                module {
                    id
                }
                pdfResponse {
                    id
                    fileName
                    filePath
                    fileUrl
                    originalName
                    mimeType
                    category
                    title
                    description
                    createdAt
                }
            }
        }
    }
`;

const GET_TRAME_By_CATEGORY = gql`
    query getAllsTramesByCategory($moduleType: String!, $moduleId: String!) {
        getAllTramesByCategory(moduleType: $moduleType, moduleId: $moduleId) {
            student {
                id
                lastName
                firstName
                email
            }
            userModuleInfo {
                pdfResponse {
                    fileName
                    originalName
                    fileUrl
                }
            }
        }
    }
`;

export {
    GET_URERS,
    GET_ALL_USERS,
    GET_CURRENT_USER,
    GET_CURRENT_USER_DETAILS,
    GET_CURRENT_EDUCATIVE_BILAN,
    LIST_STUDENTS,
    GET_MODULE_BY_ID,
    GET_USER_MODULE_INFO_BY_MODULE_ID,
    GET_USER_CONSENT,
    LIST_VIDEO_BY_MODULE,
    LIST_STUDENTS_WITH_PROGRESSION,
    LIST_ALL_PROMOTIONS,
    GET_TRAME_STUDENT,
    GET_TRAME_By_CATEGORY,
};
