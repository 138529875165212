import { Grid, IconButton } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CustomButton } from 'src/components/Button/CustomButton';

import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import logo from '../../assets/icons/Ivehte_Logo_Piste_1 2.png';
import { ROUTES } from 'src/routes/routes';
import { InputFieldPassword } from 'src/components/InputField/InputFieldPassword';
import { GppBad, GppGood } from '@mui/icons-material';
import { RESET_PASSWORD_MUTATION } from 'src/graphql/mutations/mutations';
import { useMutation } from '@apollo/client';
import PasswordResetSuccess from './PasswordResetSuccess';
import PasswordResetError from './PasswordResetError';
import { useAuthContext } from 'src/providers/AuthProvider';
import { useNavigate } from 'react-router';
import useCheckConnection from 'src/hooks/useCheckConnection';

interface PasswordResetProps {}

const resetPasswordValue = {
    password: '',
    retypePassword: '',
    token: '',
};
export const PasswordReset: FC<PasswordResetProps> = (props) => {
    const search = useLocation().search;
    const [password, setPassword] = useState(resetPasswordValue);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    
    useEffect(() => {
        const token = new URLSearchParams(search).get('token');
        if (token) setPassword((old) => ({ ...old, token: token }));
    }, [search]);

    const UserData: any = {
        password,
    };

    const [resetPasswordPassword, { data }] = useMutation(RESET_PASSWORD_MUTATION, {
        variables: {
            token: password.token,
            password: password.password,
            retypePassword: password.retypePassword,
        },
        onCompleted: (data) => {
            setPassword(resetPasswordValue);
            setSuccess(true);
        },
        onError: (error) => {
            setError(true);
        },
    });

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Link to={ROUTES.login}>
                <IconButton style={{ padding: 20 }}>
                    <img alt="icon" style={{ width: '65px', height: '60px' }} src={logo} />
                </IconButton>
            </Link>
            <Grid
                container
                justifyContent={'center'}
                justifyItems={'center'}
                alignItems={'center'}
                alignContent={'center'}
                
            >
                <Grid
            
                    style={{
                        borderRadius: 10,
                        padding: 20,
                        background: 'white',
                        marginTop: '3%',
                    }}
                >
                    {success ? (
                        <PasswordResetSuccess />
                    ) : error ? (
                        <>
                            <PasswordResetError />
                        </>
                    ) : (
                        <>
                            <Grid>
                                <CustomText
                                    style={{ textAlign: 'center', marginBottom: 10 }}
                                    fontFamily={baseFontFamily.OpenSansBold}
                                    fontSize={16}
                                    color={baseColors.green.primary}
                                >
                                    Entrer votre nouveau mot de passe
                                </CustomText>
                                <Grid container direction={'row'}>
                                    <Grid
                                        item
                                        flexGrow={1}
                                        justifyContent={'center'}
                                        style={{ paddingLeft: '3%', paddingTop: '5%' }}
                                    >
                                        <InputFieldPassword
                                            width={'97%'}
                                            placeholder="Entrer votre nouveau mot de passe"
                                            disabled={loading}
                                            label="Mot de passe : "
                                            value={password.password}
                                            onChange={(value: any) => {
                                                setPassword((old) => ({ ...old, password: value }));
                                            }}
                                        />
                                        {password?.password?.trim()?.length < 8 && (
                                            <CustomText
                                                style={{ textAlign: 'center', marginBottom: 10 }}
                                                color={baseColors.orange.primary}
                                            >
                                                Le mot de passe doit comporter au moins 8 caractères
                                            </CustomText>
                                        )}

                                        <InputFieldPassword
                                            width={'97%'}
                                            disabled={loading}
                                            placeholder="Confirmer votre mot de passe"
                                            label="Confirmer votre mot de passe :"
                                            value={password.retypePassword}
                                            onChange={(value: any) => {
                                                setPassword((old) => ({ ...old, retypePassword: value }));
                                            }}
                                        />
                                        {password.retypePassword.length > 0 &&
                                            password.password !== password.retypePassword && (
                                                <CustomText
                                                    style={{ textAlign: 'center', marginBottom: 10 }}
                                                    color={baseColors.orange.primary}
                                                >
                                                    Les mots de passe ne sont pas identiques
                                                </CustomText>
                                            )}
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent={'center'} style={{ marginTop: 10, marginBottom: 20 }}>
                                    <CustomButton
                                        width={'100%'}
                                        lower
                                        disabled={
                                            password.password !== password.retypePassword ||
                                            password.retypePassword.length === 0
                                        }
                                        loading={loading}
                                        onClick={() => resetPasswordPassword()}
                                        label="Réinitialiser le mot de passe"
                                    ></CustomButton>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
