import { Categorie } from "src/utils/constants/constants";
import { User } from "../interfaces";

export const UserAdapter = (userData: any) => {
    const roles = userData.roles;
    const parsedRoles: string[] = [];

    roles.forEach((role: string) => {
        parsedRoles.push(role.replace('ROLE_',''));
    });

    return {
        id: userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        roles: parsedRoles,
        categorie: Categorie[roles[0]],
        activated: userData.activated,
        gender: userData.sex,
        promotion : userData.promotion
    } as User;
}

