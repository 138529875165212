import { Box, Paper, Typography } from '@mui/material';
import ContentPanelViewModel from '../Home/Content/ContentPanelViewModel';
import { GET_CURRENT_EDUCATIVE_BILAN } from 'src/graphql/queries/queries';
import { useQuery } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { BilanTabMenuIconName, ModuleType, Roles } from 'src/utils/constants/constants';
import { ModuleDetailContext } from 'src/providers/ModuleDetailProvider';
import ModuleListPanel from '../Home/Content/Module/ModuleListPanel';
import ModuleDetailPanel from '../EducativeBilan/ModuleDetailPanel';
import { ModuleNonValide } from './ModuleNonValide/ModuleNonValide';
import { ErrorText } from 'src/components/Error/ErrorText';
import { CurrentModuleContext } from 'src/providers/ModuleListProvider';
import { Module } from 'src/interfaces/interfaces';
import { useDispatch } from 'react-redux';
import { setFirstModule } from 'src/redux/features/listModules/listModulesSlice';

export const EducativeWorkshopPanel = (props: any) => {
    const dispatch = useDispatch();
    const viewModel = new ContentPanelViewModel();
    const [roles, setRoles] = useState<any[]>([]);
    const [isFinalized, setIsFinalized] = useState(false);
    const [selectedPanel, setSelectedPanel] = useState<string>(BilanTabMenuIconName.INFORMATION);
    const [selectedModuleId, setSelectedModuleId] = useState<string>('');
    const [isCreatingNewModule, setIsCreatingNewModule] = useState<boolean>(false);
    const [isRefreshListModule, setIsRefreshListModule] = useState<boolean>(false);
    const [disableSoumettreButton, setDisableSoumettreButton] = useState<boolean>(false);

    const [modules, setModules] = useState<Module[]>([]);
    const { loading, data, error } = useQuery(GET_CURRENT_EDUCATIVE_BILAN);
    useEffect(() => {
        if (data) {
            if (data.currentEducativeBilan?.moduleInfo) {
                const allFinalized = data.currentEducativeBilan.moduleInfo.every((module: any) => module.isFinalized);
                setIsFinalized(allFinalized);
            }
            if (data?.currentEducativeBilan?.student.roles) {
                setRoles(data.currentEducativeBilan.student.roles);
            }
        }
    }, [data]);

    const styles = viewModel.getStyles();
    const [currentModule, setCurrentModule] = useState<Module>();

    const getEducativeBilan = (roles: any[], isFinalized: any) => {
        const expectedRoles: any[] = [Roles.STUDENT, Roles.USER];
        if (expectedRoles.toString() == roles.toString()) {
            if (isFinalized !== true) {
                return <ModuleNonValide />;
            }
        }
        return (
            <Box sx={styles.rootPanel}>
                <Paper sx={styles.paperPanel}>
                    <CurrentModuleContext.Provider
                        value={{
                            currentModule,
                            setCurrentModule,
                            modules,
                            setModules,
                        }}
                    >
                        <ModuleDetailContext.Provider
                            value={{
                                selectedPanel,
                                setSelectedPanel,
                                isCreatingNewModule,
                                isRefreshListModule,
                                setIsRefreshListModule,
                                setIsCreatingNewModule,
                                disableSoumettreButton,
                                setDisableSoumettreButton,
                            }}
                        >
                            <ModuleListPanel
                                setIsCreatingNewModule={setIsCreatingNewModule}
                                moduleType={ModuleType.EDUCATIVE_WORKSHOP}
                            />
                            <ModuleDetailPanel
                                setSelectedModuleId={setSelectedModuleId}
                                moduleType={ModuleType.EDUCATIVE_WORKSHOP}
                                // moduleId={selectedModuleId}
                                disableSoumettreButton={disableSoumettreButton}
                                setDisableSoumettreButton={setDisableSoumettreButton}
                            />
                        </ModuleDetailContext.Provider>
                    </CurrentModuleContext.Provider>
                </Paper>
            </Box>
        );
    };

    return (
        <>
            {loading ? (
                <CustomCircleLoader width={60} />
            ) : error ? (
                <ErrorText>Oups, something isn't working</ErrorText>
            ) : (
                <>{getEducativeBilan(roles, isFinalized)}</>
            )}
        </>
    );
};
