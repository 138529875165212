import { Box } from '@mui/material';
import SidebarMenuViewModel from 'src/pages/Home/Sidebar/SidebarMenuViewModel';
import SettingPanelViewModel from '../SettingViewModel';
import { useContext } from 'react';
import { SettingMenuContext, SettingMenuItem } from 'src/providers/SettingMenuProvider';

const SubmenuSetting = () => {
    const vm = new SettingPanelViewModel();
    const style = vm.getStyles();
    const sideBarVm = new SidebarMenuViewModel();
    const sideBarStyle = sideBarVm.getStyles();
    const { settingMenuItems, setSettingMenuItems } = useContext(SettingMenuContext);

    const selectSubMenu = (subMenu: SettingMenuItem) => {
        const updatedSettingMenuItems = settingMenuItems.map(item => {
            if (item.id === subMenu.id) {
                return {
                    ...item,
                    selected: true
                };
            } else {
                return {
                    ...item,
                    selected: false
                };
            }
        });
        setSettingMenuItems(updatedSettingMenuItems);
    };

    const handleSubMenuSetting = (subMenu: SettingMenuItem) => {
        return (
            <Box
                sx={{
                    ...sideBarStyle.sidebarMenuItem,
                    minWidth: '155px',
                }}
                onClick={() => {
                    selectSubMenu(subMenu);
                }}
            >
                <Box sx={sideBarStyle.titleBlock}>
                    <Box sx={sideBarStyle.icon}>{sideBarVm.parseIcon(subMenu.icon)}</Box>
                    <Box
                        sx={{
                            ...sideBarStyle.title,
                        }}
                    >
                        {subMenu.title}
                    </Box>
                </Box>
                <Box sx={vm.handleIndicator(subMenu.selected)}></Box>
            </Box>
        );
    };
    return (
        <Box
            sx={{
                ...style.box,
                width: '15%',
                display: 'flex',
                backgroundColor: 'white',
                flexDirection: 'column',
                marginBottom: '2px',
                minWidth: '155px',
            }}
        >
            {settingMenuItems.map(handleSubMenuSetting)}
        </Box>
    );
};

export { SubmenuSetting };
