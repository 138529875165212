import Resumable from 'resumablejs';
import { useEffect } from 'react';
import { colors, IconButton, Typography } from '@mui/material';
import { DownloadOutlined, VideoFileRounded } from '@mui/icons-material';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { Box } from '@mui/system';
import { baseColors } from 'src/utils/constants/baseStyles';
import LinearProgress from '@mui/material/LinearProgress';
import { CustomText } from 'src/components/Text/CustomText';
import { COMPRESS_VIDEO } from 'src/graphql/mutations/mutations';
import { useMutation } from '@apollo/client';

const inputFile = {
    display: 'none',
};
const labelFile = {
    background: baseColors.green.light,
    display: 'flex',
    color: baseColors.green.primary,
    fontWeight: 'bolder',
    padding: '10px',
    borderRadius: '5px',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'space-between'
};

interface ApiResponse {
    id: number;
    name: string;
    description: string;
}

export const VideoUploader = (props: any) => {
    const { onChange, onUploaded, nameOfFile } = props;
    const [loadingAdd, setLoadingAdd] = useState<boolean>(false);
    const [source, setSource] = useState(nameOfFile);
    const [fileName, setFileName] = useState(nameOfFile);
    const [date, setDate] = useState(Date.now);
    const [progression, setProgression] = useState<number>(0);
    const [loadingCompression, setLoadingCompression] = useState(false);
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const [compressVideo, { data, loading, error }] = useMutation(COMPRESS_VIDEO, {
        onCompleted: (data) => {
            if(data.compressUploadedVideo.code === '200'){
                setFileName(data.compressUploadedVideo.message.split(' ')[0]);
                onUploaded(true);
                setLoadingAdd(false);
            }else{
                setFileName("Une erreur s'est produite");
                setLoadingCompression(false);
                setLoadingAdd(false);
            }
        },
        onError: (error) => {
            setFileName("Une erreur s'est produite");
            setLoadingCompression(false);
            setLoadingAdd(false);
        },
    });

    useEffect(() => {
        let token = localStorage?.token;
        let endpoint = `${baseUrl}/api/batchUploads`;

        let resumable = new Resumable({
            headers: {
                Authorization: `Bearer ${token}`,
            },
            simultaneousUploads: 10,
            chunkSize: 10485760,
            forceChunkSize: false,
            target: endpoint, // URL backend à appeler pour l’envoi du fichier
            fileType: ['mp4', 'webm', 'mkv', 'flv', 'vob', 'ogv', 'ogg', 'avi', 'm4v', '3gp', '3gp2'],
            fileTypeErrorCallback: function (file: any, errorCount: any) {
                alert("C'est un fichier de type non autorisé");
            },
        });

        const btn = document.getElementById('fileInput');

        if (btn) {
            resumable.assignBrowse(btn, false);
        }

        resumable.on('progress', function () {
            setProgression(resumable.progress());
        });

        resumable.on('fileAdded', function (file, event) {
            const obj_url = URL.createObjectURL(file?.file);
            setSource(obj_url);
            setLoadingAdd(true);
            resumable.upload();
            onChange(file);
        });

        resumable.on('fileSuccess', function (file: File, response: any) {
            const serverResponse = JSON.parse(response);
            const { code, status, fileName:videoPath } = serverResponse;
            if (code === 200 && status === 'FILE_UPLOADED_WITH_SUCCESS') {
                setLoadingCompression(true);
                compressVideo({ variables: { videoPath} });
            }
        });

        resumable.on('complete', function () {
            // onUploaded(!loadingAdd);
            // setLoadingAdd(false);
            // toast.success('Video enregistré');
        });

        resumable.on('error', function () {
            setLoadingAdd(false);
            toast.error('Ouups vidéo non enregistré');
        });
    }, []);

    return (
        <div>
            <label htmlFor="fileInput" style={labelFile}>
                {' '}
                <Typography fontSize={12}>Importer la vidéo</Typography> <DownloadOutlined />{' '}
            </label>
            <input id="fileInput" type="file" style={inputFile} />
            <div>
                {loadingAdd === true ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <Box sx={{ width: '100%', mr: 1 }}>
                                <LinearProgress variant="determinate" value={progression * 100} color='primary' />
                            </Box>
                            <Box sx={{ minWidth: 23.33 }}>
                                <CustomText color="text.secondary">{`${Math.round(
                                    progression * 100,
                                )}%`}</CustomText>
                            </Box>
                        </Box>
                        <Box>
                            {loadingCompression &&
                                <CustomText color="text.secondary" style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ flexGrow: 1 }}>Compression de la vidéo en cours... Cela peut prendre plusieurs minutes. </span>
                                    <CustomCircleLoader width={20} noPadding />
                                </CustomText>}
                        </Box>
                    </Box>
                ) : source ? (
                    fileName
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
