import React, { FocusEventHandler, ReactElement, useEffect, useState } from 'react';
import { Grid, TextField, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { baseColors } from 'src/utils/constants/baseStyles';

type AppProps = {
    defaultValue?: string;
    label: string;
    placeholder: string;
    onChange: Function;
    onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
    width: any;
    height?: any;
    value?: string;
    inversed?: boolean;
    validator?: Function;
    errorMessage?: string;
    disabled?: boolean;
    multiline?: boolean;
    single?: boolean;
    idSuffix?: string;
    passwordInput?: boolean;
    defaultInputId?: string;
    onKeyPress?: Function;
    withoutLabel?: boolean;
    icon?: ReactElement;
    phonePrefix?: string;
    backgroundColor?: any;
};
const CustomTextField = styled(TextField)`
    & .MuiInputBase-root {
        background-color: rgba(250, 250, 252, 0.1);
        color: #00000;
        border-radius: 10px;
        border: 1px;
    }
`;

const useStyles = makeStyles({
    input: {
        width: '100%',
        borderWidth: 1,
        borderRadius: 60,

        '&:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 100px #000 inset',
            '-webkit-text-fill-color': '#fff',
        },
        '& label.Mui-focused': {
            borderColor: baseColors.grey,
            borderWidth: 1,
        },
        '& .MuiInput-underline:after': {
            borderColor: baseColors.grey,
            borderWidth: 1,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: baseColors.grey,
                borderWidth: 1,
            },
            '&:hover fieldset': {
                borderColor: baseColors.grey,
                borderWidth: 1,
            },
            '&.Mui-focused fieldset': {
                borderColor: baseColors.grey,
                borderWidth: 1,
            },
        },
    },
    inputInversed: {
        width: '100%',
        '& label.Mui-focused': {
            color: baseColors.green.primary,
            borderWidth: 1,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: baseColors.green.primary,
            borderWidth: 1,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: baseColors.green.primary,
                borderRadius: 10,
                borderWidth: 1,
            },
            '&:hover fieldset': {
                borderColor: baseColors.grey,
                borderWidth: 1,
            },
            '&.Mui-focused fieldset': {
                borderColor: baseColors.grey,
                borderWidth: 1,
            },
        },
    },
    inputInvalid: {
        marginTop: 10,
        width: '100%',
        '& label.Mui-focused': {
            color: baseColors.orange.primary,
            borderWidth: 1,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: baseColors.orange.primary,
            borderWidth: 1,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: baseColors.orange.primary,
                borderRadius: 10,
                borderWidth: 1,
            },
            '&:hover fieldset': {
                borderColor: baseColors.grey,
                borderWidth: 1,
            },
            '&.Mui-focused fieldset': {
                borderColor: baseColors.orange.primary,
                borderWidth: 1,
            },
        },
    },
});

export const InputCustomField = ({
    defaultValue,
    onBlur,
    value,
    validator,
    height,
    multiline,
    inversed,
    errorMessage,
    label,
    placeholder,
    onChange,
    width,
    single,
    disabled,
    idSuffix,
    passwordInput,
    defaultInputId,
    onKeyPress,
    icon,
    withoutLabel,
    phonePrefix,
}: AppProps) => {
    const classes = useStyles();
    const [isValid, setIsValid] = useState(false);
    //const [defaultValue, setDefaultValue] = useState(value);

    useEffect(() => {
        if (!validator) {
            setIsValid(true);
        } else {
            if (value && value?.length > 0 && !validator(value)) {
                setIsValid(false);
            } else {
                setIsValid(true);
            }
        }
    }, [validator, value]);

    return (
        <div style={{ width: width }}>
            <div
                style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                {label !== '' && !withoutLabel && (
                    <Grid direction="row" container alignItems="center">
                        {!isValid && (
                            <Grid item>
                                <Typography
                                    color={baseColors.orange.primary}
                                    style={{ marginLeft: 5, fontStyle: 'italic' }}
                                    fontSize={'10px'}
                                >
                                    ({errorMessage})
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                )}
                <CustomTextField
                    defaultValue={defaultValue}
                    onBlur={onBlur}
                    value={value}
                    size="small"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
                    type="text"
                    placeholder={placeholder}
                    multiline={multiline ?? false}
                    className={isValid ? (inversed ? classes.inputInversed : classes.input) : classes.inputInvalid}
                    InputProps={{
                        style: {
                            minHeight: height ?? 40,
                            fontSize: '13px'
                            // WebkitBoxShadow: "0 0 0 1000px white inset",
                        },
                        type: passwordInput ? 'password' : undefined,
                        startAdornment: (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Box mt={0.5} mr={1}>
                                    {icon}
                                </Box>
                                {phonePrefix && (
                                    <Typography
                                        fontSize={'10px'}
                                        style={{
                                            marginRight: '0.5rem',
                                            color: isValid ? '#ffffff' : baseColors.orange.primary,
                                        }}
                                        mt={0.5}
                                    >
                                        {phonePrefix}
                                    </Typography>
                                )}
                            </div>
                        ),
                    }}
                    style={{
                        // WebkitBoxShadow: "0 0 0 1000px white inset",
                        marginTop: !single ? 3 : 0,
                        marginBottom: !single ? 20 : 0,
                    }}
                    disabled={disabled ?? false}
                />
            </div>
        </div>
    );
};
