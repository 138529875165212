import { FC, ReactNode, useContext, useEffect, useState } from 'react';

import { LocalStorageService } from 'src/utils/storage';
import LoginViewModel from 'src/pages/Login/LoginViewModel';
import { createContext } from 'react';

type AuthType = {
    children?: ReactNode;
    isLoggedIn: boolean;
    setIsLoggedIn?: (val :boolean) => void;
    token?: string | null;
    login?: (token: string | null) => void;
    logout?: () => void;
    getDecodedToken?: () => any | null;
    isLoadingLogout?: boolean;
    setIsLoadingLogout?: any;
};

export const AuthContext = createContext<AuthType>({
    children: <></>,
    token: null,
    isLoggedIn: false,
    setIsLoggedIn: ()=>{},
    login: () => {},
    logout: () => {},
    getDecodedToken: () => null,
    isLoadingLogout: false,
    setIsLoadingLogout: () => {},
});

const AuthProvider: FC<AuthType> = (props) => {
    const { children } = props;
    const [token, setToken] = useState<string | null>(null);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [isLoadingLogout, setIsLoadingLogout] = useState<boolean>(false);
    const loginViewModel = new LoginViewModel();

    useEffect(() => {
        checkSession();
    }, []);

    function checkSession() {
        const loggedIn = loginViewModel.isLoggedIn();
        const storedToken = loginViewModel.getToken();
        setIsLoggedIn(loggedIn);
        setToken(storedToken);
    }
    function parseJwt(token: string) {
        // Code from https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join(''),
        );

        return JSON.parse(jsonPayload);
    }

    return (
        <AuthContext.Provider
            value={{
                token,
                isLoggedIn,
                setIsLoggedIn,
                login: (loginToken: string | null) => {
                    setToken(loginToken);
                    setIsLoggedIn(loginToken ? true : false);
                },
                logout: () => {
                    setToken(null);
                    LocalStorageService.ClearStorage();
                    checkSession();
                },
                getDecodedToken: () => {
                    return token ? parseJwt(token) : null;
                },
                isLoadingLogout,
                setIsLoadingLogout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);
export default AuthProvider;
