import { Grid, IconButton, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/components/Button/CustomButton';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import logo from '../../assets/icons/Ivehte_Logo_Piste_1 2.png';
import { Spacer } from 'src/components/Spacer/Spacer';
import SvgMail from 'src/components/CustomIcon/SvgMail';
import { useNavigate } from 'react-router';
import { ROUTES } from 'src/routes/routes';

interface NotifSeeInboxProps {
    type?: string;
}

const NotifSeeInbox: FC<NotifSeeInboxProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { type } = props;

    const getIcon = () => {
        return <SvgMail />;
    };
    return (
        <Grid>
            <Link to={ROUTES.login}>
                <IconButton style={{ padding: 20 }}>
                    <img alt="icon" style={{ width: '65px', height: '60px' }} src={logo} />
                </IconButton>
            </Link>
            <Grid
                container
                justifyContent={'center'}
                justifyItems={'center'}
                alignItems={'center'}
                alignContent={'center'}
            >
                <Grid
                    item
                    sm={8}
                    xs={8}
                    md={4}
                    lg={4}
                    style={{
                        width: '40%',
                        borderRadius: 10,
                        padding: 20,
                        background: baseColors.green.light,
                        marginTop: '3%',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        style={{
                            textAlign: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {type ? getIcon() : <SvgMail />}
                    </div>

                    <CustomText
                        style={{ textAlign: 'center', marginBottom: 20, marginTop: 10 }}
                        fontFamily={baseFontFamily.OpenSansBold}
                        fontSize={16}
                        color={baseColors.green.primary}
                    >
                        Vérifiez votre boîte mail !
                    </CustomText>
                    <CustomText
                        color={baseColors.green.primary}
                        fontSize={16}
                        style={{ textAlign: 'center', marginBottom: 20, marginTop: 10 }}
                    >
                        Nous vous avons envoyé un email avec les instructions pour réinitialiser votre mot de passe.
                    </CustomText>
                    <Grid container direction={'row'}>
                        <Grid
                            item
                            flexGrow={1}
                            justifyContent={'center'}
                            style={{ paddingLeft: '3%', paddingTop: '5%' }}
                        ></Grid>
                    </Grid>
                    <Grid
                        direction={'row'}
                        container
                        justifyContent={'center'}
                        style={{ marginTop: 20, marginBottom: 10 }}
                    >
                        <CustomButton
                            width={'60%'}
                            lower
                            loading={loading}
                            onClick={() => navigate('/login')}
                            label="RETOUR"
                        ></CustomButton>
                    </Grid>
                    <Link to={ROUTES.login} style={{ textDecoration: 'none' }}>
                        <Typography
                            style={{ textAlign: 'center', fontSize: '16px', margin: 20, textDecoration: 'underline' }}
                            color={baseColors.green.primary}
                        >
                            Vous n’avez pas reçu d’email ?<Spacer height={10}></Spacer>
                            Vérifiez vos spams ou contactez-nous !
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default NotifSeeInbox;
