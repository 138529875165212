import React, { FC } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { baseColors, baseFontFamily, baseFontWeight } from 'src/utils/constants/baseStyles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 0,
    bgcolor: baseColors.white,
    borderRadius: 2,
    p: 4,
};

interface CustomModalProps {
    toggleModal: any;
    open?: boolean;
    children: any;
    title?: string;
    width?: any;
    height?: number;
    subheader?: any;
    footer?: any;
    disableBackdropClick?: boolean;
    clearFormData?: any;
}

const CustomModal: FC<CustomModalProps> = (props) => {
    const {
        open,
        toggleModal,
        children,
        title,
        width,
        height,
        subheader,
        footer,
        clearFormData,
        disableBackdropClick,
    } = props;

    const handleClose = () => {
        toggleModal();
        clearFormData?.();
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open ?? false}
            onClose={() => (disableBackdropClick ? {} : handleClose())}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box
                    sx={{
                        ...style,
                        width: width ?? 400,
                    }}
                >
                    {!disableBackdropClick && (
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: baseColors.green.primary,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                    <Typography
                        fontWeight={baseFontWeight.medium}
                        fontSize={18}
                        color={baseColors.black}
                        fontFamily={baseFontFamily.BreeSerif}
                        textAlign={'center'}
                    >
                        {title ?? ''}
                    </Typography>
                    <Box sx={{ marginTop: 1, marginBottom: 1 }}>{subheader}</Box>
                    <Box
                        style={{
                            overflow: 'hidden',
                            overflowY: 'auto',
                            height: height ?? undefined,
                            maxHeight: '75vh',
                        }}
                    >
                        {children}
                    </Box>
                    {footer}
                </Box>
            </Fade>
        </Modal>
    );
};

export default CustomModal;
