import { FC, ReactNode } from 'react';

import AuthProvider from './AuthProvider';
import { AuthorizationProvider } from './AuthorizationProvider';
import { ExplicativeVideoProvider } from './ExplicativeVideoProvider';
import SnackBarProvider from './SnackBarProvider';
import ActionProvider from './ActionProvider';
import AvancementProvider from './AvancementProvider';
import SettingMenuProvider from './SettingMenuProvider';
import ReloadingProvider from './ReloadingProvider';

interface IProps {
    children: ReactNode;
}

export const Provider: FC<IProps> = ({ children }) => (
    <AuthorizationProvider>
        <AuthProvider isLoggedIn={false}>
            <ReloadingProvider>
                <AvancementProvider>
                    <ActionProvider>
                        <SettingMenuProvider>
                            <SnackBarProvider>{children}</SnackBarProvider>
                        </SettingMenuProvider>
                    </ActionProvider>
                </AvancementProvider>
            </ReloadingProvider>
        </AuthProvider>
    </AuthorizationProvider>
);
