import { baseColors } from 'src/utils/constants/baseStyles';

class ModuleListViewModel {
    private styles = {
        listContainer: {
            width: '20%',
            height: 'calc(100vh - 75px)',
            display: 'flex',
            minWidth: '200px',
        },
        listContent: {
            width: '100%',
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 'none',
            borderRadius: '8px',
            marginRight: '3px',
        },
        listTitle: {
            color: baseColors.black,
            fontWeight: 'bold',
            display: 'flex',
        },

        scrollableListContainer: {
            width: '100%',
            overflow: 'auto',
            scrollbarColor: baseColors.green.primary,
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
                width: '0', // You can adjust the width of the scrollbar here
                height: '0', // You can adjust the width of the scrollbar here
            },
        },
        moduleItemSelected: {
            backgroundColor: baseColors.green.primary,
        },
        moduleItem: {
            backgroundColor: baseColors.green.light,
            borderRadius: '7px',
            width: '90%',
            height: '70px',
            color: baseColors.white,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            cursor: 'pointer',
        },
        moduleVideoItem: {
            backgroundColor: baseColors.green.light,
            marginBottom : '15px',
            borderRadius: '7px',
            // minWidth: '200px',
            width: '100%',
            height: 'auto',
            color: baseColors.white,
            display: 'flex',
            // alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            padding: '15px',

            boxSizing: 'border-box',
            cursor: 'pointer',
        },
        moduleIcon: {
            marginLeft: '8px',
        },
        moduleText: {
            marginLeft: '15px',
        },

        moduleItemTitleSelected: {
            color: baseColors.white,
        },

        moduleItemDescriptionSelected: {
            color: baseColors.white,
        },

        moduleItemTitle: {
            color: baseColors.black,
            fontWeight: 'bold',
            marginBottom: '5px',
            fontSize: '11pt',
        },

        moduleItemDescription: {
            color: baseColors.green.primary,
            fontSize: '9pt',
        },
        buttonPanel: {
            width: '100%',
            height: '100px',
            display: 'flex',
            flexDirection: 'row',
            fontSize: '50pt',
        },
        buttonContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            alignContent: 'center',
            justifyContent: 'center',
        },
        button: {
            backgroundColor: baseColors.green.primary,
            fontSize: '7pt',
            fontWeight: 'bold',
            marginTop: '15px',
        },
        searchForm: {
            backgroundColor: baseColors.green.light,
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: '20px',
            marginX: '10px',
            marginY: '15px',
        },
        searchInput: {
            width: '150px',
            height: '35px',
            backgroundColor: baseColors.green.light,
            borderBottom: 'none',
            '&.Mui-focused fieldset': {
                borderColor: baseColors.green.light,
            },
            '& .MuiOutlinedInput-root': {
                '& .MuiInputBase-input': {
                    height: '1px',
                },
                '& fieldset': {
                    borderColor: baseColors.green.light,
                    width: '150px',
                    height: '35px',
                },
                '&:hover fieldset': {
                    borderColor: baseColors.green.light,
                },
                '&.Mui-focused fieldset': {
                    borderColor: baseColors.green.light,
                },
                '& input::placeholder': {
                    color: baseColors.green.dark,
                },
            },
            '& fieldset': {
                borderColor: baseColors.green.light,
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: baseColors.green.light,
            },
        },
    };
    public getStyles() {
        return this.styles;
    }
}

export default ModuleListViewModel;
