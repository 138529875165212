import { SIDEBAR_MENU_KEY, menuItems } from 'src/utils/constants/constants';

import { SidebarMenu } from './SidebarMenu';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

class SidebarMenuHelper {
    location = useLocation();
    public handleMenuOnClick(state: any, onClick?: Function) {
        if (!onClick) {
            return;
        }
        onClick();
    }
    public displaySidebarMenuItems(state: any) {
        const allSidebarMenuItems = menuItems;
        const codes = state.codes;
        const menuItemOnClick = state.menuItemOnClick;

        const sidebarMenuItems = allSidebarMenuItems.filter((sidebarMenu: any) => {
            return codes.indexOf(sidebarMenu.code) !== -1 && sidebarMenu.hidden !== true;
        });

        return sidebarMenuItems.map((sidebarMenu: any, index: number) => {
            if (this.location.pathname != '/') {
                return (
                    <SidebarMenu
                        key={sidebarMenu.id}
                        icon={sidebarMenu.icon}
                        title={sidebarMenu.title}
                        selected={false}
                        onClick={(e: any) => {
                            state.resetCurrentModuleId(null);
                            state.setSelectedMenuId(sidebarMenu.id);
                            state.selectedPanel = sidebarMenu.icon;
                            state.setSelectedPanel(sidebarMenu.icon);
                            localStorage.setItem(SIDEBAR_MENU_KEY, sidebarMenu.icon);

                            if (menuItemOnClick) {
                                menuItemOnClick();
                            }
                        }}
                    />
                );
            }
            return (
                <SidebarMenu
                    key={sidebarMenu.id}
                    icon={sidebarMenu.icon}
                    title={sidebarMenu.title}
                    selected={sidebarMenu.id === state.selectedMenuId ? true : false}
                    onClick={(e: any) => {
                        state.resetCurrentModuleId(null);
                        state.setSelectedMenuId(sidebarMenu.id);
                        state.selectedPanel = sidebarMenu.icon;
                        state.setSelectedPanel(sidebarMenu.icon);
                        localStorage.setItem(SIDEBAR_MENU_KEY, sidebarMenu.icon);

                        if (menuItemOnClick) {
                            menuItemOnClick();
                        }
                    }}
                />
            );
        });
    }
}

export default SidebarMenuHelper;
