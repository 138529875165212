import { Box, IconButton, Typography, TextField } from '@mui/material';
import { CustomButton } from 'src/components/Button/CustomButton';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import PromotionListTab from './PromotionListTab';
import { Promotion } from 'src/interfaces/interfaces';
import { useMutation, useQuery } from '@apollo/client';
import { LIST_ALL_PROMOTIONS } from 'src/graphql/queries/queries';
import { useEffect, useState, useContext } from 'react'; // Import useContext from react
import CancelIcon from '@mui/icons-material/Cancel';
import { QuestionItemViewModel } from 'src/pages/EducativeBilan/TabPanel/Quizz/QuestionItemViewModel';
import { CREATE_PROMOTION } from 'src/graphql/mutations/mutations';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import { ReloadingContext } from 'src/providers/ReloadingProvider';

const PromotionSettingPanel = () => {
    const { loading, data, error, refetch } = useQuery(LIST_ALL_PROMOTIONS);
    const [promotions, setPromotions] = useState<Promotion[]>([]);
    const [isCreating, setIsCreating] = useState(false);
    const { isReload, setIsReload } = useContext(ReloadingContext);
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext); // Use imported useContext
    const vm = new QuestionItemViewModel();
    const styles = vm.getStyles();
    const [name, setName] = useState('');

    const [createPromotion, { data: dataPromotion, loading: loadingCreatePromotion, error: errorCreatingPromotion }] =
        useMutation(CREATE_PROMOTION, {
            variables: {
                name: name,
            },
            onCompleted: (output) => {
                showSuccessSnackBar('Promotion ajoutée avec succès');
                setIsCreating(false);
                setIsReload(true);
            },
            onError: (erreur) => {
                console.log(errorCreatingPromotion);
                showErrorSnackBar("Une erreur est survenue lors de l'ajout de la promotion");
            },
        });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Add type for event parameter
        setName(event.target.value);
    };

    const createNewPromotion = () => {
        setIsCreating(true);
    };

    useEffect(() => {
        if (data && data.getAllPromotion) {
            setPromotions(data.getAllPromotion); // Simplify the setting of promotions
        }
    }, [data]); // Add data dependency to useEffect

    useEffect(() => {
        if (isReload) {
            refetch();
            setIsReload(false);
        }
    }, [isReload]);

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <Typography
                        fontFamily={baseFontFamily.BreeSerif}
                        fontWeight={400}
                        color={baseColors.green.lightPastel}
                        fontSize={18}
                    >
                        Liste des promotions
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    <CustomButton
                        width="auto"
                        label="Ajouter une nouvelle promotion"
                        onClick={createNewPromotion}
                        fontSize={14}
                        lower={true}
                    />
                </Box>
            </Box>
            {isCreating && (
                <Box padding="15px" borderRadius="2px" bgcolor={baseColors.green.light} my="15px">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography
                            fontFamily={baseFontFamily.OpenSansRegular}
                            color={baseColors.green.cancel}
                            fontSize={13}
                        >
                            Nom de la promotion :
                        </Typography>
                        <IconButton
                            onClick={() => {
                                setIsCreating(false);
                            }}
                            sx={{
                                color: baseColors.grey,
                            }}
                        >
                            <CancelIcon />
                        </IconButton>
                    </Box>
                    <Box display="flex">
                        <TextField
                            onChange={handleChange} // Use handleChange directly
                            value={name}
                            variant="outlined"
                            size="small"
                            sx={{
                                backgroundColor: baseColors.white,
                                '& .Mui-focused': {
                                    border: '1px solid',
                                    borderColor: baseColors.green.primary,
                                    '& fieldset': {
                                        border: 'none',
                                    },
                                },
                                width: '80%',
                            }}
                        />
                    </Box>
                    <Box mt="15px">
                        <CustomButton
                            width="auto"
                            height="35px"
                            label="Enregistrer"
                            onClick={createPromotion}
                            fontSize={14}
                            lower={true}
                            loading={loadingCreatePromotion}
                        />
                    </Box>
                </Box>
            )}
            <Box>
                <PromotionListTab loading={loading} promotions={promotions}></PromotionListTab>
            </Box>
        </Box>
    );
};

export default PromotionSettingPanel;
