import React, { FC, useContext, useEffect, useState } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { ADD_STUDENT_TRAME } from 'src/graphql/mutations/mutations';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { Box } from '@mui/system';
import EvaluationStudentEducativeBilanButtonAndModals from './EvaluationStudentEducativeBilanButtonAndModals';
import { useMutation } from '@apollo/client';
import EducativeBilanQuizzTypeCheckbox from './QuizzTypes/EducativeBilanQuizzTypeCheckbox';
import EducativeBilanQuizzTypeRadio from './QuizzTypes/EducativeBilanQuizzTypeRadio';
import EducativeBilanQuizzTypeText from './QuizzTypes/EducativeBilanQuizzTypeText';
import { Spacer } from 'src/components/Spacer/Spacer';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import { Typography } from '@mui/material';

interface EvaluationStudentProps {
    moduleId?: string;
    moduleType?: string;
    userModuleInfoData?: any;
    triggerOk: Function;
}

const EvaluationStudentEducativeBilan: FC<EvaluationStudentProps> = (props: any) => {
    const { moduleId, moduleType, userModuleInfoData, triggerOk } = props;

    const [trameInput, setTrameInput] = useState([]);
    const [quizz, setQuizz] = useState([]);
    const { showSuccessSnackBar, showErrorSnackBar } = useContext(SnackBarContext);

    const [addTrameStudentToBackend, mutationDataAddStudentTrame] = useMutation(ADD_STUDENT_TRAME, {
        onCompleted(data) {
            data.updateTrameEducativeBilanOrEducativeWorkshop.status === 403
                ? showErrorSnackBar('Vous avez déjà exporté votre réponse!')
                : showSuccessSnackBar('Votre réponse a été enregistrée avec succès!');
            console.log(data);
        },
        onError: (error) => {
            showErrorSnackBar();
        },
    });
    useEffect(() => {
        if (userModuleInfoData) {
            setQuizz(userModuleInfoData.getUserModuleInfoByModule.module.quizz);
            setTrameInput(() => {
                return userModuleInfoData.getUserModuleInfoByModule.trame.map((trame: any) => {
                    return {
                        questionId: trame.questionId,
                        questionText: trame.questionText,
                        answer: trame.answer,
                    };
                });
            });
        }
    }, [userModuleInfoData]);

    /** Update the trame state corresponding to user's choices */
    const updateTrameHandler = (answer: string[], quizzId: string) => {
        let newTrameInput = [...trameInput];
        newTrameInput.forEach((trame: any) => {
            if (trame.questionId === quizzId) {
                trame.answer = answer;
            }
        });
        setTrameInput(newTrameInput);
    };

    return (
        <>
            <Box sx={{ width: '100%', backgroundColor: baseColors.white }}>
                <div>
                    <Typography fontFamily={baseFontFamily.BreeSerif} color={baseColors.green.cancel} fontSize={18}>
                        EVALUATION
                    </Typography>
                    <Spacer height={25}></Spacer>
                    <Box sx={{ width: '100%' }}>
                        {trameInput.length > 0 &&
                            quizz.map((quizz: any, index: number) => {
                                switch (quizz.type) {
                                    case 'checkbox':
                                        return (
                                            <EducativeBilanQuizzTypeCheckbox
                                                key={index}
                                                quizz={quizz}
                                                trameField={trameInput[index]}
                                                triggerInput={updateTrameHandler}
                                            />
                                        );
                                    case 'radio':
                                        return (
                                            <EducativeBilanQuizzTypeRadio
                                                key={index}
                                                quizz={quizz}
                                                trameField={trameInput[index]}
                                                triggerInput={updateTrameHandler}
                                            />
                                        );
                                    case 'text':
                                        return (
                                            <EducativeBilanQuizzTypeText
                                                key={index}
                                                quizz={quizz}
                                                trameField={trameInput[index]}
                                                triggerInput={updateTrameHandler}
                                            />
                                        );
                                    default:
                                        break;
                                }
                            })}
                    </Box>

                    <EvaluationStudentEducativeBilanButtonAndModals
                        addTrameStudentToBackendFunction={addTrameStudentToBackend}
                        triggerOk={triggerOk}
                        moduleId={moduleId}
                        moduleType={moduleType}
                        trameInput={trameInput}
                    ></EvaluationStudentEducativeBilanButtonAndModals>
                </div>
            </Box>
        </>
    );
};
export default EvaluationStudentEducativeBilan;
