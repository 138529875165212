import { Grid, IconButton, Typography } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/components/Button/CustomButton';
import { CustomRadio } from 'src/components/InputField/CustomRadio';
import { InputField } from 'src/components/InputField/InputField';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import logo from '../../assets/icons/Ivehte_Logo_Piste_1 2.png';
import { ROUTES } from 'src/routes/routes';
import { isValidMail } from 'src/utils/helpers/InputValidator';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import { FORGOT_PASSWORD_MUTATION } from 'src/graphql/mutations/mutations';
import { useAuthContext } from 'src/providers/AuthProvider';

interface PasswordForgotProps {}

const PasswordForgot: FC<PasswordForgotProps> = (props) => {
    const [mail, setMail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const UserData: any = {
        mail,
    };

    const [sendUrl, { data }] = useMutation(FORGOT_PASSWORD_MUTATION, {
        variables: {
            email: mail,
        },
        onCompleted: (data) => {
            navigate('/notifSeeInbox');
            setLoading(false);
            setMail('');
        },
        onError: (error) => {
            console.log(error);
        },
    });

    return (
        <Grid>
            <Link to={ROUTES.login}>
                <IconButton style={{ padding: 20 }}>
                    <img alt="icon" style={{ width: '65px', height: '60px' }} src={logo} />
                </IconButton>
            </Link>
            <Grid
                container
                justifyContent={'center'}
                justifyItems={'center'}
                alignItems={'center'}
                alignContent={'center'}
            >
                <Grid
                    item
                    sm={8}
                    xs={8}
                    md={4}
                    lg={4}
                    style={{
                        borderRadius: 10,
                        padding: 20,
                        background: baseColors.green.light,
                        marginTop: '3%',
                    }}
                >
                    <CustomText
                        style={{ textAlign: 'center', marginBottom: 20, marginTop: 10 }}
                        fontFamily={baseFontFamily.OpenSansBold}
                        fontSize={18}
                        color={baseColors.green.primary}
                    >
                        Réinitialiser son mot de passe
                    </CustomText>
                    <Grid container direction={'row'}>
                        <CustomRadio
                            disabled
                            style={{ marginBottom: 20 }}
                            checked
                            fontFamily={baseFontFamily.OpenSansBold}
                            color={baseColors.orange.primary}
                            label="Renseigner l'adresse email associée à votre compte"
                        />
                        <CustomRadio
                            disabled
                            style={{ marginBottom: 20 }}
                            checked
                            fontFamily={baseFontFamily.OpenSansBold}
                            color={baseColors.orange.primary}
                            label="Vous recevrez un email à cette adresse. Cliquez sur le lien pour choisir un nouveau mot de passe"
                        />
                        <CustomRadio
                            disabled
                            style={{ marginBottom: 20 }}
                            checked
                            fontFamily={baseFontFamily.OpenSansBold}
                            color={baseColors.orange.primary}
                            label="Vous pouvez maintenant vous connecter à votre espace IVEHTE"
                        />
                        <Grid
                            item
                            flexGrow={1}
                            justifyContent={'center'}
                            style={{ paddingLeft: '3%', paddingTop: '5%' }}
                        >
                            <InputField
                                width={'97%'}
                                validator={isValidMail}
                                errorMessage="Adresse email non valide"
                                placeholder="Entrer votre adresse email"
                                label="Adresse e-mail :"
                                value={mail}
                                disabled={loading}
                                onChange={(value: any) => {
                                    setMail(value);
                                }}
                            />
                            {/* {error && (
                                <CustomText
                                    fontFamily={baseFontFamily.BreeSerif}
                                    style={{ marginTop: 20, textAlign: 'center' }}
                                    color={baseColors.orange.primary}
                                >
                                    L'adresse email ne correspond à aucun utilisateurs !
                                </CustomText>
                            )} */}
                        </Grid>
                    </Grid>
                    <Grid
                        direction={'row'}
                        container
                        justifyContent={'center'}
                        style={{ marginTop: 20, marginBottom: 10 }}
                    >
                        <CustomButton
                            width={'60%'}
                            lower
                            disabled={!isValidMail(mail)}
                            loading={loading}
                            onClick={sendUrl}
                            label="Réinitialiser le mot de passe"
                        ></CustomButton>
                    </Grid>
                    <Link to={ROUTES.login} style={{ textDecoration: 'none' }}>
                        <Typography
                            style={{ textAlign: 'center', fontSize: '16px', margin: 20, textDecoration: 'underline' }}
                            color={baseColors.green.primary}
                        >
                            Se connecter
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default PasswordForgot;
