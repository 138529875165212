import { StudentCardItem } from './StudentCardItem';
import useStyles from './styles/videoCardListStyles';
import { StudentCardListProps } from './Interface/StudentCardListProps';
import { StudentData } from './Interface/StudentData';
import { Box, Typography } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { useContext, useState } from 'react';
import CustomCircleLoader from '../Loader/CustomCircleLoader';
import { AvancementContext } from 'src/providers/AvancementProvider';

export const StudentCardList = (props: StudentCardListProps = { dataRows: [], loading: false }) => {
    const dataRows = props.dataRows;
    const loading = props.loading;
    const classes = useStyles();
    const [selectedId, setSelectedId] = useState('');
    const { setSelectedUserId , setSelectedUserName, setIsUserSelected, setReload} = useContext(AvancementContext);

    const getSelection = (id: string) => {
        if (id != selectedId) {
            return false;
        }
        return true;
    };

    const handleSelection = (id : string, name: string) => {
        if(id == selectedId){
            setSelectedId('');
            setSelectedUserId(null);
            setSelectedUserName(null);
            setIsUserSelected(false);
        }else{
            setSelectedId(id);
            setSelectedUserId(id);
            setSelectedUserName(name);
            setIsUserSelected(true);
        }
        setReload(true);
    }

    return (
        <Box className={classes.container}>
            <Typography
                fontFamily={baseFontFamily.BreeSerif}
                fontWeight={400}
                color={baseColors.green.cancel}
                fontSize={18}
                style={{paddingBottom : '10px'}}
            >
                Liste des étudiants
            </Typography>
            <Box
                className={classes.patientListContainer}
                sx={{
                    overflowY: 'auto',
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                    '&::-webkit-scrollbar': { width: '0' },
                }}
            >
                <>
                    {loading ? (
                        <CustomCircleLoader width={60} />
                    ) : (
                        dataRows.map((student: StudentData) => (
                            <div key={student.id} onClick={() => handleSelection(student.id, student.fullName)}>
                                <StudentCardItem
                                    id={student.id}
                                    fullName={student.fullName}
                                    progress={student.progress}
                                    photo={student.photo}
                                    selected={getSelection(student.id)}
                                />
                            </div>
                        ))
                    )}
                </>
            </Box>
        </Box>
    );
};
