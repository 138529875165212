import { FC, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Content } from './Content/Content';
import CustomSnackbar from 'src/components/CustomSnackbar';
import { Header } from './Header/Header';
import HomeViewModel from './HomeViewModel';
import { MenuIconName } from 'src/utils/constants/constants';
import { ROUTES } from 'src/routes/routes';
import { SidebarContext } from 'src/providers/SidebarProvider';
import { SidebarWrapper } from './Sidebar/SidebarWrapper';
import { useAuthContext } from 'src/providers/AuthProvider';
import { useNavigate } from 'react-router';
import useStyles from './styles';
import { ExplicativeVideoProvider } from 'src/providers/ExplicativeVideoProvider';
import CustomCircleLoader from 'src/components/Loader/CustomCircleLoader';
import useCheckConnection from 'src/hooks/useCheckConnection';

interface IProps {}

const Home: FC<IProps> = (props) => {
    const classes = useStyles();
    const [selectedPanel, setSelectedPanel] = useState(MenuIconName.BILAN);
    const viewModel = new HomeViewModel();
    const styles = viewModel.getStyles();
    const navigate = useNavigate();
    const { isLoggedIn, setIsLoggedIn, setIsLoadingLogout, isLoadingLogout } = useAuthContext();
    const isExpired = useCheckConnection(10000);
    useEffect(() => {
        // Check if there is a token in local storage
        console.log(isExpired);
        if (isExpired) {
            setIsLoggedIn && setIsLoggedIn(false);
            setIsLoadingLogout(false);
            navigate(ROUTES.login);
        }
    }, [isExpired]);

    return (
        <ExplicativeVideoProvider>
            <Box className={classes.container} sx={styles.container}>
                <SidebarContext.Provider value={{ selectedPanel, setSelectedPanel }}>
                    <Header />
                    <Box sx={styles.sidebarContent}>
                        <SidebarWrapper />
                        {isLoadingLogout ? <CustomCircleLoader /> : <Content />}
                    </Box>
                </SidebarContext.Provider>
                <CustomSnackbar open={false} message="" severity="success" />
            </Box>
        </ExplicativeVideoProvider>
    );
};

export default Home;
