import { Dispatch, SetStateAction } from 'react';
export interface HdjFilters {
    setState: Dispatch<SetStateAction<boolean>>;
    state: boolean;
}

export interface Module {
    id: string;
    title: string;
    description: string;
    video: Video | null;
    moduleIsValidated: boolean;
    videoAnnote: Video | null;
    quizz: any;
    type: string;
    moduleType: any;
}

export interface Question {
    id: string;
    type: string;
    text: string;
    rightAnswer?: string;
    choices: Choice[];
}

export interface Choice {
    id: string;
    text: string;
}

export interface Video {
    id: string;
    fileUrl?: string;
    filePath?: string;
    title: string;
    description: string;
}

export interface Module {
    id: string;
    title: string;
    description: string;
    isDeletable?: boolean;
}
export interface Document {
    name: string;
    fileContent: string;
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: string[];
    categorie: string;
    activated: boolean;
    promotion?: {
        name: string;
        id: string;
    };
}

export interface Promotion {
    id: string;
    name: string;
    isEditable: boolean;
    isDeletable: boolean;
}

export const TYPE_VIDEO_EXPLICATIVE = 'video';
export const TYPE_VIDEO_ANNOTEE = 'video_annote';
