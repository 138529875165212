import { Box } from '@mui/material';
import { FC } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { baseColors } from 'src/utils/constants/baseStyles';

interface CustomCircleLoaderProps {
    height?: number;
    width?: number;
    color?: string;
    noPadding?: boolean
}

const CustomCircleLoader: FC<CustomCircleLoaderProps> = (props) => {
    const { height, width, color, noPadding } = props;

    const boxStyles = {
        'textAlign': 'center',
        'width': noPadding ? 'auto' : '100%'
    };

    return (
        <Box sx={boxStyles}>
            <RotatingLines
                width={`${width ?? 100}`}
                strokeColor={baseColors.green.primary}
                strokeWidth="5"
                animationDuration="0.75"
                visible={true}
                />
        </Box>
    );
};

export default CustomCircleLoader;
